.project-links-container{

}

.project-links-list{
    list-style: none;
    font-size: 1.5rem;
    text-align: right;
    padding-left: 10px;
    margin-bottom: 0px;
    margin-top: 0px;
}

.project-links-icon {
    display: inline-block;
    margin-right: 10px;
    font-family: "Google Sans Regular";
}

@media (max-width: 768px) {
    .project-links-icon{
        margin-right: 10px;
    }
}