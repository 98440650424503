.project-card-container {
  height: auto;
  width: 31%;
  margin: 10px;
  margin-bottom: 30px;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 0px #e0dcdc;
  transition: box-shadow 0.3s;
  border: 0.1px solid rgb(216, 213, 213);
}

.project-card-container:hover {
  box-shadow: 0px 0px 10px 0px #beb6b6;
}

.project-card-container:hover .project-card-header {
  background-color: #001c55;
  color: white !important;
}

.project-card-header {
  height: 36px;
  width: 100%;
  font-weight: bold;
  position: relative;
  font-size: 1.02rem;
  border-radius: 15px 15px 0px 0px;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-card-header-dots-container {
  position: absolute;
  left: 8px;
}

.project-card-header-dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin: 0px 3px 0px 3px;
  display: inline-block;
}

.project-image {
  height: 100%;
  width: 100%;
  border-width: 0.5px 0px 0.5px 0px;
  border-color: rgb(232, 228, 228);
  border-style: solid;
}

.project-info {
  position: relative;
}

.project-description {
  color: rgb(64, 64, 66);
  visibility: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 98.5%;
  font-weight: 630;
  width: 100%;
  padding: 15px;
  border-bottom: 0.5px solid rgb(177, 177, 188);
  background-color: rgba(232, 240, 242, 0.8);
  font-size: 1rem;
  text-align: justify;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  transition: backdrop-filter 1s;
}

.project-card-container:hover .project-description {
  visibility: visible;
  backdrop-filter: blur(5px);
}

.project-card-footer {
  height: 38px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  text-align: center;
  border-radius: 0px 0px 10px 10px;
}

.project-links {
  height: 100%;
  width: 20%;
  border: 2px solid black;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
@media screen and (max-width: 1280px) {
  .project-card-container {
    width: 46%;
  }
}

@media screen and (max-width: 850px) {
  .project-card-container {
    width: 90%;
  }
  .project-description {
    font-weight: 500;
  }
}

@media screen and (max-width: 550px) {
  .project-card-container {
    width: 100%;
  }
}

@media screen and (max-width: 416px) {
  .project-card-header-dot {
    height: 12px;
    width: 12px;
    margin: 0px 2px 0px 2px;
  }
}

@media screen and (max-width: 400px) {
  .project-card-header-dot {
    height: 10px;
    width: 10px;
  }
  .project-description {
    font-size: 0.9rem;
  }
  .project-card-header {
    font-size: 0.9rem;
  }
}
