.contact-main {
  width: 100%;
}

.basic-contact {
  margin-left: 5%;
  margin-right: 5%;
}

.contact-heading-div {
  display: flex;
}

.blog-heading-div {
  display: flex;
}

.address-heading-div {
  display: flex;
  margin-top: 80px;
}

.blog-heading-div > * {
  flex: 1;
}

.contact-heading-div > * {
  flex: 1;
}

.address-heading-div > * {
  flex: 1;
}

.contact-heading-img-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-img {
  height: auto;
  width: auto;
  max-width: 450px;
  max-height: 450px;
  border-radius: 50%;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
  border: 10px solid white;
  margin: 50px;
}
.profile-img-loader-div {
  height: auto;
  width: auto;
  max-width: 450px;
  max-height: 450px;
  border-radius: 50%;
  margin: 50px;
  scale: 1.8;
}

.blog-heading-img-div {
  align-items: center;
  justify-content: center;
}

.contact-heading-text-div {
  text-align: center;
}

.blog-heading-text-div {
  text-align: center;
}

.address-heading-text-div {
  text-align: center;
}

.contact-heading-img-div > * {
  max-width: 100%;
  height: auto;
}

.blog-heading-img-div > * {
  max-width: 100%;
  height: auto;
}

.contact-heading-text {
  font-size: 56px;
  font-weight: 400;
  font-family: "Google Sans Medium";
  line-height: 1.1;
  text-align: center;
  margin-top: 60px;
}

.blog-heading-text {
  font-size: 56px;
  font-weight: 400;
  font-family: "Google Sans Medium";
  line-height: 1.1;
  text-align: center;
  margin-top: 40px;
}

.address-heading-text {
  font-size: 30px;
  font-family: "Google Sans Regular";
  text-align: center;
  margin-bottom: 10px;
}

.contact-header-detail-text {
  font-size: 20px;
  line-height: 30px;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Google Sans Medium";
}

.blog-header-detail-text {
  font-size: 20px;
  line-height: 30px;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Google Sans Medium";
}

.resume-btn-div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.blogsite-btn-div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.address-btn-div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0;
}

@media (max-width: 1380px) {
  .contact-heading-text {
    font-size: 50px;
    margin-top: 60px;
  }

  .blog-heading-text {
    font-size: 50px;
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .contact-heading-text {
    font-size: 30px;
    margin-top: 0px;
  }

  .blog-heading-text {
    font-size: 30px;
    margin-top: 0px;
  }
  .profile-img {
    max-width: 300px;
    max-height: 300px;
    border: 8px solid white;
    margin-top: 0px;
  }
  .profile-img-loader-div {
    max-width: 300px;
    max-height: 300px;
    margin-top: 0px;
    scale: 1.6;
  }

  .address-heading-text {
    font-size: 20px;
  }

  .blog-heading-div {
    flex-direction: column-reverse;
    margin-top: 60px;
  }

  .address-heading-div {
    flex-direction: column;
    margin-top: 60px;
  }

  .blog-heading-text-div {
    margin-top: 40px;
  }

  .address-heading-text-div {
    margin-top: 40px;
  }

  .contact-heading-div {
    flex-direction: column;
  }

  .contact-header-detail-text {
    font-size: 16px;
    margin-left: 0px;
    margin-right: 0px;
    line-height: normal;
    text-align: center;
  }

  .blog-header-detail-text {
    font-size: 16px;
    margin-left: 0px;
    margin-right: 0px;
    line-height: normal;
    text-align: center;
  }
}

@media (max-width: 450px) {
  .profile-img {
    max-width: 285px;
    max-height: 285px;
    border: 8px solid white;
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .profile-img-loader-div {
    max-width: 285px;
    max-height: 285px;
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 10px;
    scale: 1.6;
  }
}
