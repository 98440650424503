.projects-container {
    margin: 5rem 10px 5rem 10px;
}

.projects-grid{
    display:flex;
    flex-wrap:wrap;
    flex-direction: row;
    justify-content: space-evenly;
}

@media screen and (max-width: 480px) {
    .projects-container {
        margin: 10px 1px 10px 1px;
    }
}