.project-tech-stacks-list {
    list-style: none;
    font-size: 1.4rem;
    text-align: right;
    padding-left: 0;
    margin-bottom: 0px;
    margin-top: 0px;
}

.project-tech-stack-icon {
    display: inline-block;
    margin-right: 15px;
    font-family: "Google Sans Regular";
}

@media (max-width: 768px) {
    .project-tech-stack-icon{
        margin-right: 10px;
    }
}